import * as React from 'react'
import styled from 'styled-components'
import {tagToPath} from '../utils/paths'

export function TagsList({tags, ...otherProps}) {
  if (!tags || tags.length === 0) {
    return null
  }

  return (
    <Ul {...otherProps}>
      {tags.map(tag => (
        <Li key={tag}>
          <a style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `white`,
            }}key={tag} href={tagToPath(tag)}>
            {tag}
          </a>
        </Li>
      ))}
    </Ul>
  )
}

const Ul = styled.ul`
  list-style: none;
  margin: 0;
`

const Li = styled.li`
  display: inline-block;
  margin: 0;
  font-size: 17px;
  background-color: #ff457d;
  margin-right: 10px;
  margin-top: 10px;
  color: white;
  font-family: Montserrat;
  border-style: solid;
  border-color: white;
  box-shadow: 5px 10px 18px #888888;
  min-width: auto;
  padding-left: 9px;
  padding-right: 9px;
  text-align: center;
`
